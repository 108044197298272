
import Vue from "vue";
// Utilities
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { removeLocalStoreCache } from "@/utilsCache";

type ItemMenu = {
  title: string;
  link: string;
  icon: string;
};

export default Vue.extend({
  name: "CoreAppBar",
  data: () => ({
    itemsCrud: [
      { title: "Propriedade", link: "/crud/ownership", icon: "mdi-barn" },
      { title: "Talhão", link: "/crud/field", icon: "border_all" },
      { title: "Lote Boi Gordo", link: "/crud/cattle", icon: "mdi-cow" },
      // { title: "Despesa", link: "/crud/outgoing", icon: "money_off" },
      { title: "Receita", link: "/crud/income", icon: "attach_money" },
      { title: "Frete", link: "/crud/shipping", icon: "mdi-truck" },
      {
        title: "Previsão <br> Orçamentária",
        link: "/crud/budget",
        icon: "mdi-calculator",
      },
    ] as Array<ItemMenu>,
    itemsAnalytics: [
      {
        title: "Histórico Basis",
        link: "/analytics/basis_history",
        icon: "mdi-chart-bell-curve",
      },
      {
        title: "Projeção do Basis",
        link: "/analytics/basis_projection",
        icon: "mdi-chart-areaspline",
      },
    ] as Array<ItemMenu>,
    itemsTradeslip: [
      { title: "Fornecedor", link: "/tradeslip/provider", icon: "mdi-dolly" },
      { title: "Silo", link: "/tradeslip/silo", icon: "mdi-silo" },
      {
        title: "Conta Corretora",
        link: "/tradeslip/brokerage_account",
        icon: "mdi-account-cash",
      },
      {
        title: "Contrato",
        link: "/tradeslip/contract",
        icon: "mdi-file-document-edit",
      },
    ] as Array<ItemMenu>,
    showCrud: false as boolean,
    showAnalytics: true as boolean,
    disabled: false as boolean,
    absolute: false as boolean,
    openOnHover: false as boolean,
    crud: false as boolean,
    analytics: false as boolean,
    tradeslip: false as boolean,
    basis: false as boolean,
    report: false as boolean,
    closeOnClick: true as boolean,
    closeOnContentClick: true as boolean,
    offsetX: false as boolean,
    offsetY: true as boolean,
    logo: require("../../../public/img/" + process.env.VUE_APP_LOGO),
  }),
  mounted() {
    this.showCrud = this.userHasAccess("cadastros");
  },
  computed: {
    ...mapState(["drawer"]),
    ...mapGetters({
      user: "getLoggedUser",
    }),
    currentRouteName(): string {
      return this.$route.path;
    },
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    ...mapActions({
      logout: "LOGOUT",
    }),
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
        "theme",
        this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
    userHasAccess(resource: string): boolean {
      if (resource === "cadastros") {
        let allowRoles = ["admin", "broker", "N2", "N3"];
        return allowRoles.includes(this.user.userRole);
      }
      return false;
    },
    emptyCache(): void {
      try {
        removeLocalStoreCache();
        this.$alertSuccess("Sua cache foi limpa");
      } catch (error) {
        this.$alertError("Erro ao limpar cache");
        console.error(error);
      }
    },
  },
});
